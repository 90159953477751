body {
  margin-top: 0 !important;
}

.gc-center-content {
  max-width: 1250px;
  margin: auto;
}

.content {
  padding: 35px 35px 35px 35px;
  min-width: 320px;
}

.background-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: silver;
  background-size: cover;
  background-position: center;
}

.background-container-grad {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(116deg,rgba(33, 50, 60, 0.44) 34%,#ffffff 145%);
  background-size: cover;
  background-position: center;
}

.apply-container {
  background: #fff;
  border: 1px solid #e4e5e7;
  border-radius: 2px;
  padding: 20px 20px 30px 20px;
  position: relative;
  clear: both;
  overflow: auto;
  margin-bottom: 25px;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}
.apply-client-logo-container {
  width: 180px;
  height: 180px;
  position: relative;
}
.apply-client-logo {
  padding: 1rem;
  max-height: 100%;
  max-width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  position: absolute;
}

.continue-button {
  color: #fff;
  width: 100%;
  background: #1f8ce0;
  border-radius: 18px;
  padding-top: .5rem;
  padding-bottom: .5rem;
  cursor: pointer;
}


/* Basic loading styles, to use, add appropriate top, left, right */ 
div.loading {
  &:not(.responsive-table) {
    background-color: white;
    z-index: 99999;
    opacity: .7;
    transition: all .5s ease-in-out;
    &::before {
      z-index: 1000;
      filter: grayscale(100%);
      content: '';
      margin: auto;
      height: 3rem;
      width: 50px;
      position: absolute;
      background-image: url(/assets/img/brand/yc-circle-logo.png);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      animation: spin 2s linear infinite;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      text-align: center;
    }
  }
}

@media (max-width: 520px) {
  .container {
    width: inherit;
  }
  .theme-yc-default .yc-layout .yc-layout-form, .theme-yc-default .yc-layout .yc-layout-table {
    padding: 1rem !important;
  }
}

@media (max-width: 320px) {
  .container {
    max-width: 300px;
  }
}

.stats-wrapper {
  display: flex;
  justify-content: center;
  margin: 2rem 0 2rem 0;
  border-bottom: 1px solid gainsboro;
  padding-bottom: 2rem;
}

.editable .cdk-drop-list {
  border: 2px dotted #e8e8e8;
  cursor: move;
}

.editable .cdk-drop-list:hover {
  border: 2px dotted #ccc;
}


gc-wrapper-applicant {
  yc-banner {
    .banner-wrapper {
      background-color: #FCE3A9 !important;
      margin-bottom: 0 !important;
    }
  }
}

yc-dynamic-csv-import {
  .first-step-custom {
    display: flex;
    flex-basis: 50%;
    flex-direction: column;
    border-right: 1px solid gainsboro;
    padding-right: 1rem;
    .header {
      font-weight: 550;
      margin-bottom: 1rem;
    }
    .lower {
      display: flex;
      justify-content: center;
      margin-top: 1rem;
      flex-grow: 1;
      align-items: flex-end;
      margin-bottom: 2rem;
    }
  }
}

.form-component-drag {
  cursor: move;
  label {
    cursor: move;
  }
}

.details-desc {
  font-size: 14px;
  font-style: italic;
  margin-bottom: 1.5rem;
  margin-top: 0.5rem;
}

.details-header {
  font-size: 16px;
}

.details-subheader {
  font-style: italic;
}

.sky-omnibar-iframe {
  display: none !important;
  &.is-bbgm {
    display: block !important;
  }
}
.sky-omnibar-placeholder {
  display: none !important;
}