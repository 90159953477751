body {
  margin-top: 0 !important;
}

.gc-center-content {
  max-width: 1250px;
  margin: auto;
}

.content {
  padding: 35px 35px 35px 35px;
  min-width: 320px;
}

.background-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: silver;
  background-size: cover;
  background-position: center;
}

.background-container-grad {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(116deg, rgba(33, 50, 60, 0.44) 34%, #ffffff 145%);
  background-size: cover;
  background-position: center;
}

.apply-container {
  background: #fff;
  border: 1px solid #e4e5e7;
  border-radius: 2px;
  padding: 20px 20px 30px 20px;
  position: relative;
  clear: both;
  overflow: auto;
  margin-bottom: 25px;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}

.apply-client-logo-container {
  width: 180px;
  height: 180px;
  position: relative;
}

.apply-client-logo {
  padding: 1rem;
  max-height: 100%;
  max-width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  position: absolute;
}

.continue-button {
  color: #fff;
  width: 100%;
  background: #1f8ce0;
  border-radius: 18px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  cursor: pointer;
}

/* Basic loading styles, to use, add appropriate top, left, right */
div.loading:not(.responsive-table) {
  background-color: white;
  z-index: 99999;
  opacity: 0.7;
  transition: all 0.5s ease-in-out;
}
div.loading:not(.responsive-table)::before {
  z-index: 1000;
  filter: grayscale(100%);
  content: "";
  margin: auto;
  height: 3rem;
  width: 50px;
  position: absolute;
  background-image: url(/assets/img/brand/yc-circle-logo.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  animation: spin 2s linear infinite;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
}

@media (max-width: 520px) {
  .container {
    width: inherit;
  }
  .theme-yc-default .yc-layout .yc-layout-form, .theme-yc-default .yc-layout .yc-layout-table {
    padding: 1rem !important;
  }
}
@media (max-width: 320px) {
  .container {
    max-width: 300px;
  }
}
.stats-wrapper {
  display: flex;
  justify-content: center;
  margin: 2rem 0 2rem 0;
  border-bottom: 1px solid gainsboro;
  padding-bottom: 2rem;
}

.editable .cdk-drop-list {
  border: 2px dotted #e8e8e8;
  cursor: move;
}

.editable .cdk-drop-list:hover {
  border: 2px dotted #ccc;
}

gc-wrapper-applicant yc-banner .banner-wrapper {
  background-color: #FCE3A9 !important;
  margin-bottom: 0 !important;
}

yc-dynamic-csv-import .first-step-custom {
  display: flex;
  flex-basis: 50%;
  flex-direction: column;
  border-right: 1px solid gainsboro;
  padding-right: 1rem;
}
yc-dynamic-csv-import .first-step-custom .header {
  font-weight: 550;
  margin-bottom: 1rem;
}
yc-dynamic-csv-import .first-step-custom .lower {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  flex-grow: 1;
  align-items: flex-end;
  margin-bottom: 2rem;
}

.form-component-drag {
  cursor: move;
}
.form-component-drag label {
  cursor: move;
}

.details-desc {
  font-size: 14px;
  font-style: italic;
  margin-bottom: 1.5rem;
  margin-top: 0.5rem;
}

.details-header {
  font-size: 16px;
}

.details-subheader {
  font-style: italic;
}

.sky-omnibar-iframe {
  display: none !important;
}
.sky-omnibar-iframe.is-bbgm {
  display: block !important;
}

.sky-omnibar-placeholder {
  display: none !important;
}

div.rich-email-content table[bgcolor="#606972"] {
  display: none;
}
div.rich-email-content center {
  background-color: inherit !important;
}
div.rich-email-content table[align=center] {
  background-color: inherit;
}
div.rich-email-content html,
div.rich-email-content body {
  margin: 0 auto !important;
  padding: 0 !important;
  height: 100% !important;
  width: 100% !important;
}
div.rich-email-content * {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
div.rich-email-content div[style*="margin: 16px 0"] {
  margin: 0 !important;
}
div.rich-email-content table tr,
div.rich-email-content td tr {
  border-left: none;
}
div.rich-email-content ul li {
  font-size: 13px !important;
}
div.rich-email-content.is-modal-view table[align=center] {
  width: auto !important;
}
div.rich-email-content table[align=left] {
  display: none;
}
div.rich-email-content table table table {
  table-layout: auto;
}
div.rich-email-content img {
  -ms-interpolation-mode: bicubic;
}
div.rich-email-content *[x-apple-data-detectors],
div.rich-email-content .x-gmail-data-detectors,
div.rich-email-content .x-gmail-data-detectors *,
div.rich-email-content .aBn {
  border-bottom: 0 !important;
  cursor: default !important;
  color: inherit !important;
  text-decoration: none !important;
  font-size: inherit !important;
  font-family: inherit !important;
  font-weight: inherit !important;
  line-height: inherit !important;
}
div.rich-email-content .a6S {
  display: none !important;
  opacity: 0.01 !important;
}
div.rich-email-content img.g-img + div {
  display: none !important;
}
div.rich-email-content .button-link {
  text-decoration: none !important;
}
div.rich-email-content .email-container {
  border: 1px solid gainsboro;
  width: 500px !important;
}
@media only screen and (min-device-width: 375px) and (max-device-width: 413px) {
  div.rich-email-content .email-container {
    min-width: 375px !important;
  }
}
@media screen and (max-width: 480px) {
  div.rich-email-content div > u ~ div .gmail {
    min-width: 100vw;
  }
}
div.rich-email-content .button-td,
div.rich-email-content .button-a {
  transition: all 100ms ease-in;
}
div.rich-email-content .button-td:hover,
div.rich-email-content .button-a:hover {
  opacity: 0.9;
}
@media screen and (max-width: 600px) {
  div.rich-email-content .email-container {
    width: 100% !important;
    margin: auto !important;
  }
  div.rich-email-content .fluid {
    max-width: 100% !important;
    height: auto !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  div.rich-email-content .stack-column,
  div.rich-email-content .stack-column-center {
    display: block !important;
    width: 100% !important;
    max-width: 100% !important;
    direction: ltr !important;
  }
  div.rich-email-content .stack-column-center {
    text-align: center !important;
  }
  div.rich-email-content .center-on-narrow {
    text-align: center !important;
    display: block !important;
    margin-left: auto !important;
    margin-right: auto !important;
    float: none !important;
  }
  div.rich-email-content table.center-on-narrow {
    display: inline-block !important;
  }
}

#toast-container {
  /*overrides*/
}
#toast-container > div {
  border-radius: 0;
  box-shadow: none;
  overflow: visible;
  margin-bottom: 0.625em;
  opacity: 0.9;
}
#toast-container > div:hover {
  box-shadow: none;
  border-radius: 0;
  opacity: 1;
}
#toast-container .toast-action {
  width: 100%;
  margin-top: 10px;
  border-radius: 1.3rem;
  background-color: rgba(0, 0, 0, 0.3);
}

yc-button yc-fa:first-of-type {
  margin-right: none;
}
yc-button yc-fa:first-of-type + yc-i18n {
  margin-left: 0.5rem;
}

.dropdown-item.with-icon-description {
  display: flex;
  width: 300px;
  white-space: normal;
}
.dropdown-item.with-icon-description yc-fa {
  margin-right: 0.25rem;
}

yc-switch .wrapper {
  background-color: #0974a1 !important;
}